import React from 'react'
import SectionSitemap from '../components/SectionSitemap/SectionSitemap'
import * as styles from '../styles/local.module.scss'
import { graphql } from 'gatsby';
import Seo from '../components/Shared/Seo/Seo'

const PageNotFound = ({
    location,
    pageContext,
    data: {
        contentfulPageCustom: {
            seoTitle,
            seoDescription,
            slug
        },
        allContentfulPageCustom: {
            nodes: allLinks
        }
    }
}) => {
    return (
        <div>
            <Seo slug={slug} title={seoTitle} description={seoDescription} lang={pageContext.node_locale} hrefLangs={allLinks} />
            <div className={styles.container}>
                <h1 className={styles.title}>Page Not Found</h1>
                <p className={styles.content}>
                    <span>We couldn’t find the information you’re looking for.</span>
                    <span>Please click on the navigation above to “tea” up a new page.</span>
                </p>
                <SectionSitemap />
            </div>
        </div>
    )
}

export const NotFoundQuery = graphql`
query NotFoundQuery($id: String!, $contentful_id: String!){
    contentfulPageCustom(id: {eq: $id}){
        seoTitle
        slug
        seoDescription
    }
    allContentfulPageCustom(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}}){
        nodes {
            slug
            node_locale
        }
    }
}
`

export default PageNotFound